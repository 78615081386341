<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-8 pt-5 pt-md-8" />
    <b-container class="mt--7" fluid>
      <b-row>
        <b-col md="3">
          <b-list-group>
            <b-list-group-item :to="{ name: 'settings' }"> General </b-list-group-item>
            <b-list-group-item :to="{ name: 'setting-pages' }"> Site pages </b-list-group-item>
            <b-list-group-item :to="{ name: 'setting-users' }"> Users </b-list-group-item>
            <b-list-group-item :to="{ name: 'setting-plans' }"> Plans </b-list-group-item>
            <b-list-group-item :to="{ name: 'setting-shop' }"> Shop Settings </b-list-group-item>
          </b-list-group>
        </b-col>
        <b-col md="9" class="pl-md-5">
          <card>
            <router-view />
          </card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
export default {
  name: "Settings",
};
</script>
